import { Home } from './screens';
import './App.css';

function App() {
  return (
    <Home />
  );
}

export default App;
