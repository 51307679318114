import React from "react";
import styles from "./footer.module.css";
import Logo from "../../resources/logo-footer.png";
import Redes from "../../resources/redes-footer.png";
import { Text } from "../text";

export const Footer = () => {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.content}>
          <img className={styles.image} src={Logo} alt={"Footer-logo"} />
          <div className={styles.center}>
          <Text>Av. Rivera Navarrete 762, Piso 6, San Isidro</Text>
          <Text className={styles.line}>  |  </Text>
          <Text>administracion@markel.al</Text>
          <Text className={styles.line}>  |  </Text>
          <Text>+51 700 1850</Text>
          </div>
          <img className={styles.image} src={Redes} alt={"Footer-redes"} />
        </div>
      </div>
      <div className={styles.blackContent}>
        <Text>Copyright 2023 markel - Todos los derechos reservados</Text>
      </div>
    </>
  );
};
