import React from "react";
import { Strong } from "../text";
import styles from "./header.module.css";
import ArrowRight from '../../resources/web/tresflechas_derecha.svg';
import ArrowLeft from '../../resources/web/tresflechas_izquierda.svg';
import { Link } from 'react-scroll';

export const Header = () => {
  return (
    <div className={styles.header}>
      <img src={ArrowLeft} className={styles.arrowLeft} alt={'brand-markel'}/>
      <img src={ArrowRight} className={styles.arrowRight} alt={'brand-markel'}/>
      <Strong className={styles.title}>Estrategia & Soluciones</Strong>
      <p className={styles.subtitle}>
        diseñadas específicamente <br /> para la cobranza más eficiente
      </p>
      <p className={styles['end-text']}>Descubre el poder de la integración entre</p>
      <p className={styles['end-text']}>inteligencia artificial (IA) y el expertise humano</p>
      <Link to="section4" smooth={true} className={styles['button-end']}>
        Deseo saber más
      </Link>
    </div>
  );
};
