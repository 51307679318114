import React from "react";
import { FirstContent } from "../components/first-content";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { NavBar } from "../components/navbar";
import { SecondContent } from "../components/second-content";
import { ThirdContent } from "../components/third-content";

export const Home = () => {
  return (
    <>
      <NavBar />
      <Header />
      <FirstContent />
      <SecondContent />
      <ThirdContent />
      <Footer />
    </>
  );
};
