import React from "react";
import styles from "./navbar.module.css";
import Logo from "../../resources/web/logo_markel.svg";
import { Link } from "react-scroll";

export const Menu = () => {
  return (
    <div className={styles.menu}>
      <Link to="section1" smooth={true}>
        <p className={styles.link}>Modelo</p>
      </Link>
      <Link to="section2" smooth={true}>
        <p className={styles.link}>Beneficios</p>
      </Link>
      <Link to="section3" smooth={true}>
        <p className={styles.link}>Nosotros</p>
      </Link>
      <Link to="section4" smooth={true}>
        <p className={styles.last}>Contacto</p>
      </Link>
    </div>
  );
};

export const NavBar = () => {
  return (
    <div className={styles.navbar}>
      <img src={Logo} className={styles.brand} alt={"brand-markel"} />
      <Menu />
    </div>
  );
};
