import React from "react";
import { Margin } from "../Margin";
import { Strong, Text } from "../text";
import styles from "./second-content.module.css";
import ArrowsRight from '../../resources/web/dosflechas_derecha.svg';
import ArrowsLeft from '../../resources/web/dosflechas_izquierda.svg';

export const SecondContent = () => {
  return (
    <div id={'section3'} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.firstContent}>
          <Strong fontSize={45}>Nosotros</Strong>
          <Margin bottom={3.6}/>
          <Text fontSize={25}>
            <Strong>Somos un equipo altamente capacitado en gestión de portafolios de
            bancos y financieras.</Strong> Poseemos una visión global y transparente, que
            ofrece soluciones flexibles, omnicanal, e incluyen un agente virtual
            con habilidades de comunicación y capacidad cognitiva, para sostener
            interacciones habladas o escritas de manera centralizada y
            eficiente.
          </Text>
        </div>
        <div className={styles.secondContent}>
          <img src={ArrowsRight} className={styles.arrowsRight} alt={'arrow'} />
          <img src={ArrowsLeft} className={styles.arrowsLeft} alt={'arrow'} />
          <Text className={styles.secondText} fontSize={40}>La estrategia se <br/>trata de elegir<br/> deliberadamente <br/>ser diferente</Text>
        </div>
      </div>
    </div>
  );
};
