import React from "react";
import { Margin } from "../Margin";
import { Strong, Text } from "../text";
import styles from "./third-content.module.css";
import Location from '../../resources/web/ubicacion.svg';
import Correo from '../../resources/web/correo.svg';
import Phone from '../../resources/web/telefono.svg';

export const ThirdContent = () => {
  return (
    <>
      <Margin id={'section4'} bottom={20} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.firstContent}>
            <Text fontSize={48}>
              Escríbenos o <br />
              contáctanos y <br />
              descubre el poder <br />
              que nuestras
              <br />
              soluciones tienen <br /> para tu negocio
            </Text>
            <Margin bottom={7.5} />
            <div className={styles.imgContent}>
              <img src={Location} alt={'end-label'} />
              <Text fontSize={20}>
                Av. Rivera Navarrete 762, Piso 6, San Isidro
              </Text>
            </div>
            <Margin bottom={2.4} />
            <div className={styles.imgContent}>
              <img src={Correo} alt={'end-label'} />
              <Text fontSize={20}>administracion@markel.al</Text>
            </div>
            <Margin bottom={2.4} />
            <div className={styles.imgContent}>
              <img src={Phone} alt={'end-label'} />
              <Text fontSize={20}>+51 1 700 1850</Text>
            </div>
          </div>
          <div className={styles.secondContent}>
            <input placeholder={"Nombre*"} className={styles.inputContent} />
            <input placeholder={"Apellido*"} className={styles.inputContent} />
            <input
              placeholder={"Correo corporativo*"}
              className={styles.inputContent}
            />
            <input placeholder={"Teléfono*"} className={styles.inputContent} />
            <input placeholder={"Compañía*"} className={styles.inputContent} />
            <textarea placeholder={"Mensaje*"} className={styles.areaContent} />
            <div className={styles.formContent}>
              <input className={styles.radius} type="radio" id="huey" />
              <Text>
                Estoy de acuerdo con los <Strong>Términos y Condiciones</Strong>{" "}
                y la <Strong>Política de privacidad</Strong>
              </Text>
            </div>
            <div className={styles["button-end"]}>Enviar</div>
          </div>
        </div>
      </div>
      <Margin bottom={18} />
    </>
  );
};
