import React from "react";
import { Margin } from "../Margin";
import { Strong, Text } from "../text";
import styles from "./first-content.module.css";
import { data } from "./mock";
import Arrow from '../../resources/web/flechita_verde.svg';
import Celere from '../../resources/web/celere.svg';
import Continue from '../../resources/web/continuidad.svg';
import Seguridad from '../../resources/web/seguridad.svg';
import Escalabilidad from '../../resources/web/escalabilidad.svg';
import Optimizacion from '../../resources/web/optimizacion_costos.svg';
import { Link } from "react-scroll";

const FirstCardsContent = ({ first, second, texts }) => {
  return (
    <div className={styles["team-container-content"]}>
      <Margin bottom={4.4} />
      {first && <Strong fontSize={30}>{first}</Strong>}
      {second !== "" && <Text fontSize={28}>{second}</Text>}
      {second !== "" ? <Margin bottom={2.4} /> : <Margin bottom={5.8} />}
      <div className={styles.teamul}>
        {texts.map((item) => (
          <div className={styles.liContainer}>
            <img src={Arrow} alt={'short icon'} />
            <p className={styles.teamli}>{item}</p>
          </div>
        ))}
      </div>
      <Margin bottom={3.4} />
    </div>
  );
};

export const FirstContent = () => {
  return (
    <div className={styles.container} id={'section1'}>
      <Text fontSize={40} className={styles.title}>
        Realizamos una <Strong>Gestión Inteligente</Strong> con Soluciones
      </Text>
      <Text fontSize={40} className={styles.title}>
        Estratégicas y Tecnológicas a través de nuestro modelo:
      </Text>
      <Margin bottom={12} />
      <div className={styles.team}>
        <div className={styles["team-content"]}>
          <Strong fontSize={40}>T</Strong>
        </div>
        <div className={styles["team-content"]}>
          <Strong fontSize={40}>E</Strong>
        </div>
        <div className={styles["team-content"]}>
          <Strong fontSize={40}>A</Strong>
        </div>
        <div className={styles["team-content"]}>
          <Strong fontSize={40}>M</Strong>
        </div>
      </div>
      <Margin bottom={3} />
      <div className={styles["team-container"]}>
        {data.map((item) => (
          <FirstCardsContent {...item} />
        ))}
      </div>
      <Margin bottom={4.8} />
      <Link to="section4" smooth={true} className={styles["button-end"]}>Me interesa el modelo</Link>
      <Margin id={'section2'} bottom={20} />
      <Text fontSize={40} className={styles.title}>
        Los mejores resultados son posibles
      </Text>
      <Text fontSize={40} className={styles.title}>
        gracias a la mejor gestión, <Strong>la nuestra</Strong>
      </Text>
      <Margin bottom={8.4} />
      <div className={styles.cards}>
        <div className={styles["card-first"]}>
          <Text fontSize={40}>Nuestros</Text>
          <Text fontSize={40}>principales</Text>
          <Strong fontSize={40}>Beneficios</Strong>
        </div>
        <div className={styles.card}>
          <Strong fontSize={36}>Célere</Strong>
          <Strong fontSize={36}>implementación</Strong>
          <Margin bottom={5.76} />
          <img src={Celere} className={styles.ilustration} alt={'celere ilustration'} />
          <Margin bottom={5.76} />
          <p>
            Nuestras metodologías y la tecnología que empleamos hacen posible
            iniciar operaciones y obtener resultados con mayor celeridad.
          </p>
        </div>
        <div className={styles.card}>
          <Strong fontSize={36}>Servicio</Strong>
          <Strong fontSize={36}>sin pausas</Strong>
          <Margin bottom={5.76} />
          <img src={Continue} className={styles.ilustration} alt={'celere ilustration'} />
          <Margin bottom={5.76} />
          <p>
            Nuestro modelo garantiza la continuidad de las funciones y
            actividades frente a cualquier contingencia o proceso de mejora.
          </p>
        </div>
        <div className={styles.card}>
          <Strong fontSize={36}>Seguridad</Strong>
          <Strong fontSize={36}>garantizada</Strong>
          <Margin bottom={5.76} />
          <img src={Seguridad} className={styles.ilustration} alt={'celere ilustration'} />
          <Margin bottom={5.76} />
          <p>
            Resguardamos la información con la mejor infraestructura y el empleo
            de metodologías que garantizan su adecuado uso.
          </p>
        </div>
        <div className={styles.card}>
          <Strong fontSize={36}>Total</Strong>
          <Strong fontSize={36}>escalabilidad</Strong>
          <Margin bottom={5.76} />
          <img src={Escalabilidad} className={styles.ilustration} alt={'celere ilustration'} />
          <Margin bottom={5.76} />
          <p>
            Controlamos el dimensionamiento de la operación en tiempo real y sin
            producir afectaciones en el servicio.
          </p>
        </div>
        <div className={styles.card}>
          <Strong fontSize={36}>Optimización</Strong>
          <Strong fontSize={36}>los resultados</Strong>
          <Margin bottom={5.76} />
          <img src={Optimizacion} className={styles.ilustration} alt={'celere ilustration'} />
          <Margin bottom={5.76} />
          <p>
            El modelo que empleamos nos permite maximizar los resultados y
            materializarlos de forma ágil.
          </p>
        </div>
      </div>
      <Margin bottom={4.8} />
      <Link to="section4" smooth={true} className={styles["button-end"]}>Deseo los beneficios</Link>
      <Margin bottom={20} />
    </div>
  );
};
