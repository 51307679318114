import React from "react";
import styles from "./text.module.css";

export const Strong = ({ children, className, fontSize }) => {
  return (
    <span
      className={`${styles.strong} ${className}`}
      style={{ fontSize: `${fontSize}px` }}
    >
      {children}
    </span>
  );
};

export const Text = ({ children, className, fontSize }) => {
  return (
    <span className={`${className}`} style={{ fontSize: `${fontSize}px` }}>
      {children}
    </span>
  );
};
