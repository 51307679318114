export const data = [
  {
    first: "Tecnologia",
    second: "",
    texts: [
      "Soluciones Cloud",
      "Inteligencia Artificial",
      "Omnicanalidad",
      "Agentes Virtuales",
      "RPA",
      "Speech Analytics",
      "Seguridad de la información",
    ],
  },
  {
    first: "Estrategica",
    second: "y Analítica",
    texts: [
      "Anticipación de la Necesidad",
      "Perfilamiento de Clientes",
      "Estrategia del operador",
      "Scoring de Cobranzas",
      "Definición de metas del negocio",
      "Proyecciones",
    ],
  },
  {
    first: "Adaptabilidad",
    second: "",
    texts: [
      "Capacidad de reacción",
      "inmediata",
      "Servicio 100%",
      "customizable",
    ],
  },
  {
    first: "Maximizador",
    second: "de resultados",
    texts: ["Preventiva", "Temprana", "Intermedia", "Tardía", "Judicial"],
  },
];
